<template lang="">
    <div>
        <Add />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Müşteri Ekle", route: "/customer/add/" },
      ]);
    },
   components:{
    Add : () =>import("@/components/customer/Add")
   }
}

</script>
<style lang="">
    
</style>